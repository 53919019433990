import React, { useState, useEffect, useContext } from "react";
import useMedia from "use-media";
import { Tooltip } from "antd";
import {
  Crown,
  GlobeIcon,
  NotificationIconRed,
  TeamDefaultAvatar,
  TeamIcon,
  NoNotificationIcon,
} from "../../../../assets/svg";
import { Button } from "../../common/Button";
import Avatar from "../../common/Avatar";
import * as S from "./styles";
import PlayerInviteModal from "../PlayerInviteModal";
import { SpinnerLoader } from "../../common/SpinningLoader";
import { RequestContext } from "../../../../providers/RequestProvider";
import { useGetSecHeaders } from "../../../../hooks";
import { truncateWithDots } from "../../../../helpers/utils";

const MyTeam = ({ teamData, displayInviteModal, isLoading }) => {
  const [sortedPlayers, setSortedPlayers] = useState([]);
  const [playerRequests, setPlayerRequests] = useState([]);
  const [isCaptain, setIsCaptain] = useState([]);
  const headers = useGetSecHeaders();

  const isMobile = useMedia({ maxWidth: "768px" });
  const isSmallScreen = useMedia({ maxWidth: "355px" });

  //return the long language name based on teamData.language
  const getLanguageName = language => {
    switch (language) {
      case "en":
        return "English";
      case "es":
        return "Spanish";
      case "fr":
        return "French";
      case "de":
        return "German";
      case "pt":
        return "Portuguese";
      case "tl":
        return "Tagalog";
      default:
        return "English";
    }
  };

  useEffect(() => {
    if (teamData?.players && teamData?.players?.length > 0) {
      let temp = teamData.players?.slice(); // creates a copy
      let captainIndx = temp.findIndex(player => player?.role?.toLowerCase() === "captain");
      let captainObj = temp[captainIndx];
      temp.splice(captainIndx, 1);
      if (captainObj) temp.unshift(captainObj); // captainObj might be undefined if no player has role "captain". Need to prevent unshifting undefined to the array
      let teamMembers = temp.filter(item => item?.status === "accepted");

      setSortedPlayers(teamMembers);

      let tempPlayerRequests = temp.filter(item => item?.status === "pending-accept");
      setPlayerRequests(tempPlayerRequests);
    }
  }, [teamData?.players]);

  useEffect(() => {
    if (teamData?.players && teamData?.players?.length > 0) {
      // get just the team captains
      let temp = teamData?.players?.filter(item => item?.role?.toLowerCase() === "captain");
      setIsCaptain(temp);
    }
  }, [teamData?.players]);

  return (
    <>
      <S.Card style={isSmallScreen ? { minWidth: "290px", marginLeft: "4px" } : { minWidth: "325px" }}>
        <S.Flex columnGap={21} justifyContent="space-between" alignItems="flex-start">
          <S.Flex columnGap={21}>
            <div>
              {teamData?.thumbnail ? (
                <Avatar size={isMobile ? "46px" : "66px"} src={teamData?.thumbnail} />
              ) : (
                <TeamDefaultAvatar
                  style={isMobile ? { width: "46px", height: "46px" } : { width: "66px", height: "66px" }}
                />
              )}
            </div>
            <S.Flex direction="column" alignItems="stretch">
              <S.Flex columnGap={26} alignItems="center">
                {teamData?.status === "inactive" ? (
                  <S.TeamName>{isMobile ? truncateWithDots(teamData?.name, 10) : teamData?.name}</S.TeamName>
                ) : (
                  <S.Link href={`/#/team-profile/${teamData?.id}`} rel="noreferrer">
                    <S.TeamName>{isMobile ? truncateWithDots(teamData?.name, 10) : teamData?.name}</S.TeamName>
                  </S.Link>
                )}
                <Tooltip
                  color="#7265C8"
                  placement="top"
                  title={`This team has ${teamData?.players?.length} players of ${teamData?.usersLimit}`}
                >
                  <S.Text color="#A498ED">
                    {sortedPlayers?.length}/<span style={{ color: "#fff" }}>{teamData?.usersLimit}</span>
                  </S.Text>
                </Tooltip>
              </S.Flex>
              <S.Flex columnGap={21}>
                <S.Flex columnGap={10} alignItems="center">
                  <TeamIcon style={{ height: "18px", width: "18px" }} />
                  <S.Text size={isMobile ? "13" : "16"} color="#A498ED">
                    {sortedPlayers?.length} players
                  </S.Text>
                </S.Flex>
                <S.Flex columnGap={10} alignItems="center">
                  <GlobeIcon />
                  <S.Text size={isMobile ? "13" : "16"} color="#A498ED">
                    {getLanguageName(teamData?.language)}
                  </S.Text>
                </S.Flex>
              </S.Flex>
            </S.Flex>
          </S.Flex>
          <Tooltip title={teamData?.status === "inactive" ? "The team has been deactivated by an administrator" : ""}>
            <S.TeamStatusWrapper alignItems="start" style={{ height: "100%" }}>
              <S.TeamStatusDot status={teamData?.status} />
              <S.TeamStatusText>{teamData?.status}</S.TeamStatusText>
            </S.TeamStatusWrapper>
          </Tooltip>
        </S.Flex>

        <div style={{ marginTop: "26px" }}>
          <S.PlayerListContainer>
            <S.PlayerListInner>
              {sortedPlayers?.map((player, indx) => (
                <div key={indx} style={{ display: "flex", justifyContent: "space-between", paddingRight: "12px" }}>
                  <S.PlayerRow>
                    <S.Text>{indx + 1}</S.Text>
                    <Avatar src={player?.avatarUrl} size="25px" />
                    <S.Text>{player?.nick}</S.Text>
                  </S.PlayerRow>
                  {player?.role?.toLowerCase() === "captain" ? (
                    <Tooltip title="Captain" color="#7265C8" placement="top">
                      <Crown style={{ marginTop: "5px" }} />
                    </Tooltip>
                  ) : null}
                </div>
              ))}
            </S.PlayerListInner>
          </S.PlayerListContainer>
        </div>
        {/* if the role is captain then display this content, otherwise display nothing */}

        {isCaptain.map((captain, indx) => {
          // compare if the userId of the captain is the same userId as the logged in user
          if (captain?.userId === headers.userId) {
            return (
              <div style={{ marginTop: "18px" }} key={indx}>
                <S.Flex justifyContent="space-between" columnGap="12">
                  <S.Flex columnGap={14}>
                    {teamData?.requestJoin > 0 ? (
                      <NotificationIconRed />
                    ) : (
                      <NoNotificationIcon style={{ width: "19px", height: "19px" }} />
                    )}
                    <S.Text color="#A498ED" size={isMobile ? "13" : "16"}>
                      {teamData?.requestJoin === 0 ? "No" : teamData?.requestJoin} Pending{" "}
                      {teamData?.requestJoin === 1 ? "Request" : "Requests"}
                    </S.Text>
                  </S.Flex>
                  <Button
                    background="#6C47FF"
                    borderRadius="18.5px"
                    style={{ minWidth: "120px" }}
                    disabled={teamData?.status === "inactive"}
                  >
                    {/* {isLoading === true ? <SpinnerLoader /> : "Invite Players"} */}
                    <S.Link href={`/#/team-profile/${teamData?.id}`} rel="noreferrer">
                      {isMobile ? `Team details` : `Go to team details`}
                    </S.Link>
                  </Button>
                </S.Flex>
              </div>
            );
          } else {
            return (
              <div style={{ marginTop: "18px" }} key={indx}>
                <S.Flex justifyContent="flex-end">
                  <Button
                    background="#6C47FF"
                    borderRadius="18.5px"
                    style={{ minWidth: "120px" }}
                    disabled={teamData?.status === "inactive"}
                  >
                    <S.Link href={`/#/team-profile/${teamData?.id}`} rel="noreferrer">
                      {isMobile ? `Team details` : `Go to team details`}
                    </S.Link>
                  </Button>
                </S.Flex>
              </div>
            );
          }
        })}
      </S.Card>
      {/* <PlayerInviteModal /> */}
    </>
  );
};

export default MyTeam;
