import React, { useContext, useEffect, useRef, useState } from "react";
import TeamProfileBanner from "../../components/v2/Teams/TeamProfileBanner";
import * as S from "./styles";
import TeamStats from "../../components/v2/Teams/TeamStats";
import { Link, useParams } from "react-router-dom";
import { useGetSecHeaders } from "../../hooks/SecurityHeaders";
import useCallAxios from "../../hooks/useAxiosCall";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../components/v2/common/SpinningLoader";
import MemberRow from "../../components/v2/Teams/MemberRow";
import RequestRow from "../../components/v2/Teams/RequestRow";
import { Button } from "../../components/v2/common/Button";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useHistory, useLocation } from "react-router-dom";
import { RequestContext } from "../../providers/RequestProvider";
import TournamentsSection, { TournamentRow } from "./TournamentsSection";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../constants";
import Flex from "../../components/v2/common/Flex";
import { TextNoMargin } from "../../components/v2/common/Text";
import { BackArrow, ErrorCross, WarningIcon } from "../../assets/svg";

// TODO:
// 1. Confirm that we need to prevent inviting memebers after userLimit has been reached
// 2. Get APIs for uploading avatar and cover photo
// 3. Confirm if we need to have any actions when player list is clicked in profile banner
// 4. Confirm requests API call
// 5. API giving error if player not connected

const TeamProfile = () => {
  const { setTotalRequests, setUserTeamRequestsToJoin, userTeamRequestsToJoin, totalRequests } =
    useContext(RequestContext);
  const { id } = useParams();
  const [teamData, setTeamData] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Setting isLoading to true to ensure nothing is shown until the API call has fetched data
  const [isOwner, setIsOwner] = useState(false);
  const [sortedPlayers, setSortedPlayers] = useState([]);
  const [playerRequests, setPlayerRequests] = useState([]);
  const [bannerImages, setBannerImages] = useState([]);
  const [activePools, setActivePools] = useState([]);
  const [allPools, setAllPools] = useState([]);
  const [isLoadingAllPools, setIsLoadingAllPools] = useState(false);
  const [isCurrentUserCaptain, setIsCurrentUserCaptain] = useState(false);

  const history = useHistory();
  const search = useLocation().search;

  const showRequests = new URLSearchParams(search).get("show-requests");

  const [activeTab, setActiveTab] = useState(showRequests ? "Requests" : "Players");

  const [isMounted, setIsMounted] = useState(false); // using this to prevent a warning regarding memory leaks

  const { isMobile } = useWindowSize();

  useEffect(() => {
    //using this to prevent a warning regarding memory leaks
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();

  async function getPoolsData() {
    if (!headers.encryptedHeader) return;
  }

  async function getData() {
    setTeamData(null);
    const response = await callAxiosFunc({
      method: "GET",
      url: `team/${id}?viewAll=true`,
      headers: headers.encryptedHeader
        ? JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader })
        : null,
    });

    const activePoolsResp = await callAxiosFunc({
      method: "GET",
      url: `team/${id}/pools?onlyActive=true`,
      headers: headers.encryptedHeader
        ? JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader })
        : null,
    });
    if (response && response.err) {
      history.push("/404");
    }

    if ((response && response.err) || (activePoolsResp && activePoolsResp.err)) {
      console.log(response.err);
    }

    setIsLoading(false);
    if (!activePoolsResp?.err) {
      setActivePools(activePoolsResp.data);
    }

    setTeamData({
      ...response.data,
      joinedActiveMembers: response?.data?.players.filter(player => player.status === "accepted"),
    });
  }

  // calling this function separately from the getData because Tino informed that this API call will take more time
  async function getAllPools() {
    if (!isMounted || !headers.encryptedHeader) return;

    setIsLoadingAllPools(true);
    const allPoolsResp = await callAxiosFunc({
      method: "GET",
      url: `team/${id}/pools?onlyActive=false`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });
    if (allPoolsResp && allPoolsResp.err) {
      // history.push("/404");
      console.log(allPoolsResp.err);
    }

    if (!allPoolsResp?.err) {
      setAllPools(allPoolsResp.data);
    }
    setIsLoadingAllPools(false);
  }
  async function getBannerImages() {
    const response = await callAxiosFunc({
      method: "GET",
      url: `team/banners`,
    });
    if (response && response.err) {
      // history.push("/404");
      console.log(response.err);
    } else {
      setBannerImages(response.data);
    }
  }

  async function updateTeamData(data, setCustomLoading) {
    if (!isMounted) return;
    if (setCustomLoading) {
      setCustomLoading(true);
    }
    const response = await callAxiosFunc({
      method: "PUT",
      url: `team/${id}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      body: JSON.stringify({ ...data }),
    });
    if (setCustomLoading) {
      setCustomLoading(false);
    }
    if (response && response.err) {
      return { err: response.err };
    } else {
      return response;
    }
  }

  useEffect(() => {
    getBannerImages();
  }, []);

  // TODO: Remove headers from dependency array of the first useEffect. I have included it there right now because API is failing without headers
  useEffect(() => {
    // Get Team Data once id is available
    if (id) {
      getData();
    }
    if (headers.userId) {
      getAllPools();
    }
  }, [id, headers.userId]);

  useEffect(() => {
    if (teamData?.owner) {
      setIsOwner(teamData?.owner === headers?.userId);
    }
  }, [teamData?.owner, headers?.userId]);

  useEffect(() => {
    if (teamData?.players && teamData?.players?.length > 0) {
      let temp = teamData.players?.slice(); // creates a copy
      let captainIndx = temp.findIndex(player => player?.role?.toLowerCase() === "captain");
      let captainObj = temp[captainIndx];
      temp.splice(captainIndx, 1);

      if (captainObj) temp.unshift(captainObj); // captainObj might be undefined if no player has role "captain". Need to prevent unshifting undefined to the array
      let teamMembers = temp.filter(item => item?.status !== "pending-accept");

      setSortedPlayers(teamMembers);

      let tempPlayerRequests = temp.filter(item => item?.status === "pending-accept");
      setPlayerRequests(tempPlayerRequests);

      setIsCurrentUserCaptain(captainObj?.userId === headers?.userId);
    }
  }, [teamData?.players, headers?.userId]);
  const playerSectionRef = useRef(null);
  const handleScroll = () => {
    playerSectionRef.current.scrollIntoView({
      top: 1000,
      behavior: "smooth",
    });
  };

  const checkTeamLengthAndNotify = () => {
    // We run this function directly after changing sortedPlayers.
    // Therefore sortedArray doesn't have the updated state at that time.
    // Because of this, here we are adding 1 to sortedPlayers.length in order to cover up for the fact that sortedPlayers hasn't been updated when this function is called
    if (sortedPlayers?.length + 1 >= teamData?.usersLimit) {
      toast(<div>Your team is full</div>, TOAST_OPTIONS);
    }
  };
  // Functions related to request and player sections
  const respondToRequest = async (status, userId) => {
    const response = await callAxiosFunc({
      method: "POST",
      url: `team/join/${id}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      body: JSON.stringify({ status, userId }),
    });
    if (response && response.err) {
      return { err: response.err };
    } else {
      return response;
    }
  };
  const scrollRequestsIntoView = () => {
    setActiveTab("Requests");
    handleScroll();
  };

  //TODO: Refactor this logic
  const handleRemoveUser = userId => {
    setSortedPlayers(sortedPlayers.filter(player => player.userId !== userId));
  };

  // This function will modify the sorted players list and requests list states when the user accepts or denys an incoming request
  const handlePlayersListState = (status, playerArg) => {
    // modifying state if a captain invites a player to the team
    // modifying state if a captain invites a player to the team
    if (status === "sent-request") {
      setSortedPlayers([...sortedPlayers, { ...playerArg, status: "pending-confirm" }]);
      checkTeamLengthAndNotify();
    }

    // modifying state if a captain cancels a pending invite
    if (status === "cancel-request") {
      setSortedPlayers(sortedPlayers.filter(player => player.userId !== playerArg.userId));
    }
    // pass in the status "remove" when removing a player
    if (status === "remove") {
      setSortedPlayers(sortedPlayers.filter(player => player.userId !== playerArg.userId));
      setTeamData({
        ...teamData,
        joinedActiveMembers: teamData?.joinedActiveMembers?.filter(item => item.userId !== playerArg.userId),
      });
    } else {
      // else block will handle when playuer accepts or denies a request
      setPlayerRequests(playerRequests.filter(player => player.userId !== playerArg.userId));

      if (status === "accepted") {
        setSortedPlayers([...sortedPlayers, { ...playerArg, status: "accepted" }]);
        setTeamData({
          ...teamData,
          joinedActiveMembers: [...teamData?.joinedActiveMembers, playerArg],
        });
        checkTeamLengthAndNotify();
      }

      // Moodifying global state
      let index = userTeamRequestsToJoin?.findIndex(item => item?.teamId === id); //Finding index of current team refrerence in the teams array in context

      if (typeof index === "number" && index != -1) {
        // Checking if array found and is valid
        let tempArr = [...userTeamRequestsToJoin]; // Creating copy of arrray
        tempArr[index] = { ...tempArr[index], teamRequestJoin: tempArr[index]["teamRequestJoin"] - 1 }; // Making change  at the index found in the previous method. The change is to basically decrease one from the teamRequestJoin property of the team data in the array

        setUserTeamRequestsToJoin(tempArr); // setting state to the tempArr
      }
      if (totalRequests > 0) {
        setTotalRequests(totalRequests - 1); // decreasing one from the global totalRequests state
      }
    }
  };
  async function captainChangeApiCall(newCaptainUserId, teamId) {
    // // Make Api Call To Change Captain
    if (!headers?.encryptedHeader) return;
    try {
      const response = await callAxiosFunc({
        method: "PUT",
        url: `/team/chgCaptain/${teamId}/${newCaptainUserId}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });
      return response;
    } catch (err) {
      console.log("err ==> ", err);
    }
  }
  const handleCaptainChange = async (userIdToMakeCaptain, cb) => {
    // Call API function here
    let res = await captainChangeApiCall(userIdToMakeCaptain, teamData.id);
    if (cb) cb();
    if (res.err) {
      toast(
        <div>
          <Flex alignItems="center">
            <ErrorCross style={{ height: "40px", width: "40px" }} />
            <div>Something went wrong</div>
          </Flex>
        </div>,
        TOAST_OPTIONS,
      );
      return;
    }
    // let temp = { ...teamData };
    // let updatedTemp = temp.players?.map(item => {
    //   if (item?.role === "captain") return { ...item, role: "member" };
    //   else if (item?.userId === userIdToMakeCaptain) return { ...item, role: "captain" };
    //   else return item;
    // });

    setTeamData(prev => ({
      ...prev,
      players: res?.data?.data,
    }));
  };
  const removeMemberFromTeam = async userId => {
    const response = await callAxiosFunc({
      method: "DELETE",
      url: `team/user/${id}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      body: JSON.stringify({ userId }),
    });
    if (response && response.err) {
      return { err: response.err };
    } else {
      return response;
    }
  };
  const cancelInvite = async userId => {
    const response = await callAxiosFunc({
      method: "POST",
      url: `team/join/${id}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      body: JSON.stringify({ status: "cancelled", userId }),
    });
    if (response && response.err) {
      return { err: response.err };
    } else {
      return response;
    }
  };

  return (
    <>
      {/* <ToastContainer /> */}
      {isLoading || !teamData ? (
        <SpinnerLoaderWrapper style={{ marginTop: "200px" }}>
          <SpinnerLoader style={{ width: "50px", height: "50px" }} />
        </SpinnerLoaderWrapper>
      ) : (
        <S.TeamProfileContainer>
          <div style={{ marginBottom: "13px", textAlign: "left" }}>
            <Link to="/teams">
              <BackArrow />
            </Link>
          </div>
          <TeamProfileBanner
            // teamName={teamData?.name}
            // players={teamData?.players}
            // country={teamData?.country}
            // teamAvatarUrl={teamData?.thumbnail}
            // teamId={teamData?.id}
            // usersLimit={teamData?.usersLimit}
            {...teamData}
            playerRequests={playerRequests}
            isOwner={isOwner}
            updateTeamData={updateTeamData}
            bannerImages={bannerImages}
            scrollRequestsIntoView={scrollRequestsIntoView}
            handlePlayersListState={handlePlayersListState}
            sortedPlayers={sortedPlayers}
            activeAndPendingMembers={sortedPlayers?.filter(
              (player = {}) => player.status === "accepted" || player.status === "pending-confirm",
            )}
            activePools={activePools}
            handleRemoveUser={handleRemoveUser}
            headers={headers}
          />

          {teamData?.stats && teamData?.stats?.length > 0 ? <TeamStats stats={teamData?.stats} /> : null}

          {activePools?.length > 0 && (
            <Flex
              direction="column"
              extraStyles={{
                marginTop: teamData?.stats && teamData?.stats?.length ? "54px" : "128px",
              }}
              rowGap="24"
            >
              <TextNoMargin fontSize="24px">Active Tournaments</TextNoMargin>
              <S.TournamentRowHeadings>
                <div>
                  <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
                    Game
                  </TextNoMargin>{" "}
                </div>
                <div></div>
                <div>
                  <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
                    Date
                  </TextNoMargin>
                </div>
                <div>
                  <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
                    Time
                  </TextNoMargin>
                </div>
                <div>
                  <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
                    Entry Fees
                  </TextNoMargin>
                </div>
                <div>
                  <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
                    Prize Pool
                  </TextNoMargin>
                </div>
                <div>
                  <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
                    Teams
                  </TextNoMargin>
                </div>
                <div />
              </S.TournamentRowHeadings>

              <Flex direction="column" rowGap="21">
                {activePools?.map(pool => (
                  <TournamentRow
                    data={pool}
                    active={
                      pool.poolStatus === "RUNNING" ||
                      pool.poolStatus === "NOT_STARTED" ||
                      pool.poolStatus === "JOINING" ||
                      pool.poolStatus === "PROCESSING_REGISTERED_USERS"
                    }
                  />
                ))}
              </Flex>
            </Flex>
          )}

          <S.FlexButtons>
            <Button
              onClick={() => setActiveTab("Players")}
              background={
                activeTab === "Players"
                  ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
                  : "rgb(63, 47, 170);"
              }
              height="47px"
              width="116px"
              fontWeight="325"
              borderRadius="30px"
              fontSize="16px"
            >
              Players
            </Button>

            {isOwner && (
              <Button
                onClick={() => setActiveTab("Requests")}
                background={
                  activeTab === "Requests"
                    ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
                    : "rgb(63, 47, 170);"
                }
                height="47px"
                width="116px"
                fontWeight="325"
                borderRadius="30px"
                fontSize="16px"
                style={{ position: "relative" }}
              >
                Requests
                {playerRequests.length > 0 && <S.Badge>{playerRequests.length}</S.Badge>}
              </Button>
            )}

            <Button
              onClick={() => setActiveTab("Tournaments")}
              background={
                activeTab === "Tournaments"
                  ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
                  : "rgb(63, 47, 170);"
              }
              height="47px"
              width="161px"
              fontWeight="325"
              borderRadius="30px"
              fontSize="16px"
            >
              Tournaments
            </Button>
          </S.FlexButtons>
          <div ref={playerSectionRef}>
            {activeTab === "Tournaments" ? (
              <TournamentsSection activePools={activePools} allPools={allPools} isLoadingAllPools={isLoadingAllPools} />
            ) : activeTab === "Players" ? (
              sortedPlayers
                ?.filter(item => item.status !== "deleted" && item.status !== "rejected" && item.status !== "cancelled")
                .map((item, indx) => (
                  <MemberRow
                    key={indx}
                    item={item}
                    isOwner={isOwner}
                    isMobile={isMobile}
                    removeMemberFromTeam={removeMemberFromTeam}
                    handlePlayersListState={handlePlayersListState}
                    sortedPlayers={sortedPlayers}
                    usersLimit={teamData?.usersLimit}
                    activePools={activePools}
                    cancelInvite={cancelInvite}
                    handleCaptainChange={handleCaptainChange}
                    isCurrentUserCaptain={isCurrentUserCaptain}
                  />
                ))
            ) : (
              playerRequests?.map((item, indx) => (
                <RequestRow
                  teamStatus={teamData?.status}
                  key={indx}
                  item={item}
                  isMobile={isMobile}
                  respondToRequest={respondToRequest}
                  handlePlayersListState={handlePlayersListState}
                  activePools={activePools}
                  sortedPlayers={sortedPlayers}
                />
              ))
            )}
          </div>
        </S.TeamProfileContainer>
      )}
    </>
  );
};

export default TeamProfile;
