import React, { useState } from "react";
import useCallAxios from "../../hooks/useAxiosCall";
import { BE_API_BASE_URL } from "../../constants/env";
import { CopyIcon, Crown, Podium, PodiumNew, TickSuccessNewPurple } from "../../assets/svg";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import { truncateWithDots } from "../../helpers/utils";
import { useEffect } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import * as S from "./styles";
import Avatar from "../../components/v2/common/Avatar";
import Flex from "../../components/v2/common/Flex";
import Loader from "../../components/v2/Loader";
import Modal from "../../components/v2/Modal";
import PersonMoving from "../../assets/gif/person-moving.gif";

export const PlayerModal = ({ showModal, toggleModal, players, joinMode }) => {
  const toastOptions = {
    autoClose: 6000,
    bodyClassName: "toast-custom-body",
    className: "toast-custom-class",
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
    style: {
      color: "#D1CCEF",
      background: "#3A2F81",
      width: "400px",
      borderRadius: "6px",
      float: "right",
      marginRight: "63px",
    },
    closeButton: false,
  };

  const { isMobile } = useWindowSize();
  const copyAccountNameToClipboard = accountName => {
    navigator.clipboard.writeText(accountName);
    toast(
      <Flex alignItems="center">
        <TickSuccessNewPurple style={{ height: "40px", width: "40px" }} />{" "}
        {`${accountName} was copied to the clipboard successfully`}
      </Flex>,
      toastOptions,
    );
  };
  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <S.ModalContainer>
        <S.ModalHeader>{joinMode === "SOLO" ? "Player Information" : "Team Members"}</S.ModalHeader>
        <S.PreviewContainer>
          <S.HeaderContainer>
            <S.Label>{isMobile ? truncateWithDots("Blast Nickname", 10) : "Blast Nickname"}</S.Label>
            <S.Label>{isMobile ? truncateWithDots("Game Account", 10) : "Game Account"}</S.Label>
          </S.HeaderContainer>
          {players?.map((player, index) => (
            <>
              <S.PreviewBlock key={index}>
                <Flex columnGap="75">
                  <Flex columnGap="12" alignItems="center">
                    <Avatar size="22px" src={player?.thumbnail} />
                    <S.Nickname>{player?.nick}</S.Nickname>
                    {player?.role === "captain" && <Crown />}
                  </Flex>
                </Flex>
                <Flex columnGap="10" alignItems="center">
                  <S.Nickname>{player?.accountName}</S.Nickname>
                  <Tooltip
                    title="Copy game account"
                    color="#7265C8"
                    placement="top"
                    overlayStyle={{
                      fontSize: "12px",
                      borderRadius: "6px",
                      zIndex: 999999,
                      alignItems: "center",
                    }}
                  >
                    <S.CopyButton onClick={() => copyAccountNameToClipboard(player?.accountName)}>
                      <CopyIcon />
                    </S.CopyButton>
                  </Tooltip>
                </Flex>
              </S.PreviewBlock>
            </>
          ))}
        </S.PreviewContainer>
      </S.ModalContainer>
    </Modal>
  );
};

const PlayerBlock = ({ nickname, avatar, ind, playerData, joinMode, accountName, platformUserId }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(prev => !prev);
  };

  const playerDataToDisplay =
    joinMode === "SOLO"
      ? [{ accountName: accountName || platformUserId, nick: nickname, platformUserId, thumbnail: avatar }]
      : playerData?.players;

  return (
    <>
      <S.PlayerBlock onClick={toggleModal} joinMode={joinMode}>
        <S.PlayerDetails>
          <small>{ind}</small>
          <Avatar src={avatar} alt="User Avatar" />
          <div>{nickname}</div>
        </S.PlayerDetails>
      </S.PlayerBlock>
      <PlayerModal joinMode={joinMode} showModal={showModal} toggleModal={toggleModal} players={playerDataToDisplay} />
    </>
  );
};

// This BracketsPlayers is names incorrectly as it is also being used to show leaderboard players. Renaming in many places will cause conflicts so not renaming right now
const BracketsPlayers = ({ players, poolId, poolStatus, joinMode }) => {
  const { callAxiosFunc } = useCallAxios();
  const [isLoadingPlayers, setIsLoadingPlayers] = useState(false);
  const [playersByBranch, setPlayersByBranch] = useState({});

  const getPlayersByBranch = async () => {
    setIsLoadingPlayers(true);
    try {
      const response = await callAxiosFunc({
        method: "GET",
        url: `${BE_API_BASE_URL}poolData/players/groupedByBranch/${poolId}
        `,
      });
      if (!response?.error && response.data) {
        setPlayersByBranch(response?.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingPlayers(false);
    }
  };

  useEffect(() => {
    if ((poolStatus == "RUNNING" || poolStatus == "FINISHED") && joinMode !== "TEAM") {
      getPlayersByBranch();
    }
  }, [poolId]);

  if (isLoadingPlayers) {
    return (
      <S.LoaderWrapper>
        <Loader animation="rotate" width={120} />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.SectionContainer>
      {poolStatus &&
      (poolStatus == "RUNNING" || poolStatus == "FINISHED") &&
      Object.keys(playersByBranch).length > 0 &&
      !playersByBranch.error &&
      playersByBranch.data?.length > 0 ? (
        <div>
          {playersByBranch.data.map((item, index) => (
            <>
              <h1 style={{ color: "white", marginTop: "32px", marginBottom: "0px" }}>{`Branch ${item.branch}`}</h1>
              <S.PlayerBlocksContainer key={index}>
                {item?.players?.map(({ nickname, avatarUrl, teamMembers, accountName, platformUserId }, ind) => (
                  <PlayerBlock
                    nickname={nickname}
                    accountName={accountName}
                    platformUserId={platformUserId}
                    avatar={avatarUrl}
                    i={ind + 1}
                    playerData={teamMembers}
                    joinMode={joinMode}
                  />
                ))}
              </S.PlayerBlocksContainer>
            </>
          ))}
        </div>
      ) : players?.length > 0 ? (
        <S.PlayerBlocksContainer>
          {players &&
            players.map((player, ind) => (
              <PlayerBlock
                nickname={player?.nick}
                avatar={player?.avatarURL}
                accountName={player?.accountName}
                platformUserId={player?.platformUserId}
                ind={ind + 1}
                joinMode={joinMode}
                playerData={player?.playerData}
              />
            ))}
        </S.PlayerBlocksContainer>
      ) : (
        <S.NoPlayersContainer>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <img src={PersonMoving} alt="person moving the head" width={100} height={100} />
            <PodiumNew style={{ width: "500px"}} />
          </Flex>
          <p style={{fontSize: "24px"}}>No players found</p>
        </S.NoPlayersContainer>
      )}
    </S.SectionContainer>
  );
};

export default BracketsPlayers;
