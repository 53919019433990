import React, { useEffect, useState } from "react";
import { Switch, Tooltip } from "antd";
import { ToastContainer, toast } from "react-toastify";

import * as S from "./styles";

import defaultBanner from "../../../../assets/team-profile-default.png";
import secondBanner from "../../../../assets/banner-2.png";
import thirdBanner from "../../../../assets/banner-3.png";
import fourthBanner from "../../../../assets/banner-4.png";
import fifthBanner from "../../../../assets/banner-5.png";
import Avatar from "../../common/Avatar";
import { Button } from "../../common/Button";
import { Text, TextNoMargin } from "../../common/Text";
import Flex, { FlexStyled } from "../../common/Flex";
import {
  Camera,
  CheckMarkIcon,
  Delete,
  DottedMenu,
  EditIcon,
  GlobeIcon,
  NotificationIcon,
  NotificationIconRed,
  TeamIcon,
  Tick,
  TickSuccess,
  TransparentArrow,
  WarningIcon,
} from "../../../../assets/svg";
import { useRef } from "react";
import Modal from "../../Modal";
import PlayerInviteModal from "../PlayerInviteModal";
import DeleteModal from "../DeletePlayerModal";
import { Select } from "antd";
import useImageUpload from "../../../../hooks/UploadImage";
import { SpinnerLoader } from "../../common/SpinningLoader";
import { TOAST_OPTIONS } from "../../../../constants";
import useCallAxios from "../../../../hooks/useAxiosCall";
import { ENDPOINT } from "../../../../constants/endpoints";

const { Option } = Select;

const toastOptions = {
  autoClose: 6000,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  bodyClassName: "toast-custom-body",
  className: "toast-custom-class",
  style: {
    color: "#D1CCEF",
    background: "#3A2F81",
    width: "400px",
    borderRadius: "6px",
    float: "right",
    marginRight: "63px",
  },
  closeButton: false,
};

const BannerMenu = ({ toggleDeleteModal, setEditLanguage, setEditTeamName, showDeleteModal }) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div onClick={() => setShowMenu(prev => !prev)} style={{ position: "relative", cursor: "pointer" }}>
      <DottedMenu />

      {showMenu && (
        <S.Popover>
          <S.PopoverButtons
            onClick={() => {
              setEditLanguage(true);
              setEditTeamName(true);
            }}
          >
            <EditIcon style={{ height: "15px", width: "15px" }} />
            <TextNoMargin fontSize="15px" fontWeight="325" cursor="pointer">
              Edit
            </TextNoMargin>
          </S.PopoverButtons>
          <S.PopoverButtons onClick={toggleDeleteModal}>
            <Delete />
            <TextNoMargin fontSize="15px" fontWeight="325" cursor="pointer">
              Delete Team
            </TextNoMargin>
          </S.PopoverButtons>
        </S.Popover>
      )}
    </div>
  );
};

const TeamNameInput = ({ editTeamName, setEditTeamName, teamName, setTeamName, updateTeamData }) => {
  const [isLoading, setIsLoading] = useState(false);
  return editTeamName ? (
    <S.InputBoxContainer>
      <input
        value={teamName}
        onChange={e => setTeamName(e.target.value)}
        style={{ width: "100%", background: "transparent", border: "0px", outline: "none", fontSize: "28px" }}
        placeholder="Edit Team Name"
        bordered={false}
      />
      <S.TransparentArrowContainer>
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <TransparentArrow
            onClick={async () => {
              const res = await updateTeamData({ name: teamName }, setIsLoading);
              if (res?.err) {
                toast(
                  <Flex alignItems="center">
                    <WarningIcon style={{ height: "40px", width: "40px" }} />
                    {res?.err?.response?.data
                      ? res?.err?.response?.data?.[0]
                      : "Something went wrong. Please try again!"}
                  </Flex>,
                  toastOptions,
                );
                return;
              }
              setEditTeamName(false);
            }}
          />
        )}
      </S.TransparentArrowContainer>
    </S.InputBoxContainer>
  ) : (
    <TextNoMargin color="#fff" fontSize="28px" fontWeight="325" lineHeight="36px">
      {teamName}
    </TextNoMargin>
  );
};

const LanguageDropdown = ({
  editLanguage,
  languages,
  setEditLanguage,
  updateTeamData,
  teamLanguage,
  setTeamLanguage,
  languageMap,
}) => {
  const closeDropdown = () => setOpenDropdown(false);
  const [_, setOpenDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function confirmSelection() {
    // setEditLanguage(false);

    const res = await updateTeamData({ language: teamLanguage }, setIsLoading);
    if (res?.err) {
      toast(
        <Flex alignItems="center">
          <WarningIcon style={{ height: "40px", width: "40px" }} /> Something went wrong. Please try again!
        </Flex>,
        toastOptions,
      );
      return;
    }
    setEditLanguage(false);
  }
  return editLanguage ? (
    <S.SelectContainer>
      <Select
        dropdownStyle={{ background: "#3F2FAA", width: "234.34px" }}
        defaultOpen={true}
        onSelect={closeDropdown}
        onClick={closeDropdown}
        showArrow={false}
        bordered={false}
        defaultValue={teamLanguage}
        onChange={setTeamLanguage}
      >
        {languages.map(item => (
          <Option
            style={{
              color: "#fff",
              background: teamLanguage === item ? "#4A39BA" : "transparent",
            }}
            value={item}
          >
            {languageMap[item]}
          </Option>
        ))}
      </Select>
      <S.TransparentArrowContainer>
        {isLoading ? <SpinnerLoader /> : <TransparentArrow onClick={confirmSelection} />}
      </S.TransparentArrowContainer>
    </S.SelectContainer>
  ) : (
    <TextNoMargin color="#A498ED" fontSize="16px" fontWeight="325" lineHeight="19px">
      {languageMap[teamLanguage]}
    </TextNoMargin>
  );
};

const VisibilityToggle = ({ teamVisibility, setTeamVisibility, updateTeamData }) => {
  const [isLoading, setIsLoading] = useState(false);

  async function toggleVisibility(checked) {
    const newVisibility = checked ? "visible" : "hidden";
    const currentVisibility = teamVisibility;

    setTeamVisibility(newVisibility);

    const res = await updateTeamData({ visibility: newVisibility }, setIsLoading);
    if (res?.err) {
      setTeamVisibility(currentVisibility);
      toast(
        <Flex alignItems="center">
          <WarningIcon style={{ height: "40px", width: "40px" }} /> Something went wrong. Please try again!
        </Flex>,
        toastOptions,
      );
      return;
    }
  }
  return (
    <Flex columnGap={10} alignItems="center">
      <Switch
        style={{
          backgroundColor: teamVisibility === "hidden" ? "#312C8380" : "#3f2faa",
        }}
        checked={teamVisibility === "visible"}
        onChange={toggleVisibility}
        disabled={isLoading}
      />
      <TextNoMargin color="#A498ED" fontSize="16px">
        {isLoading ? <SpinnerLoader /> : teamVisibility == "visible" ? "Public" : "Private"}
      </TextNoMargin>
    </Flex>
  );
};

const bannersList = [defaultBanner, secondBanner, thirdBanner, fourthBanner, fifthBanner];

const TeamProfileBanner = props => {
  const profilePicInputRef = useRef(null);
  const bannerPicInputRef = useRef(null);
  const [showBannerList, setShowBannerList] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editLanguage, setEditLanguage] = useState(false);
  const [editTeamName, setEditTeamName] = useState(false);
  const [teamName, setTeamName] = useState(props.name);
  const [teamLanguage, setTeamLanguage] = useState(props.language || "English");
  const [teamThumbnail, setTeamThumbnail] = useState(props.thumbnail);
  const [teamBanner, setTeamBanner] = useState(props.banner);
  const [teamVisibility, setTeamVisibility] = useState(props.visibility);
  const [pendingRequests] = useState(() => props?.players?.filter(player => player?.status === "pending-accept"));
  const [selectedBannerImage, setSelectedBannerImage] = useState("");
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [requestSent, setRequestSent] = useState(
    props?.players
      ?.filter(player => player.status === "pending-accept")
      ?.map(player => player.userId)
      ?.includes(props?.headers?.userId) || false,
  );
  const [showLeaveMenu, setShowLeaveMenu] = useState(false);
  const [disableLeaveButton, setDisableLeaveButton] = useState(false);

  const teamId = props?.id;
  const [bannerUploading, setBannerUploading] = useState(false);
  const [thumbnailUploading, setThumbnailUploading] = useState(false);
  const [isThumbnailTransparent, setIsThumbnailTransparent] = useState(false);

  const uploadImge = useImageUpload();
  const { callAxiosFunc } = useCallAxios();

  const handleProfilePicClick = () => {
    if (profilePicInputRef.current) {
      profilePicInputRef.current.click();
    }
  };

  const handleBannerSelectionClick = () => {
    if (bannerPicInputRef.current) {
      bannerPicInputRef.current.click();
    }
  };

  function toggleShowBannerList() {
    setShowBannerList(prev => !prev);
  }

  function toggleDeleteModal() {
    setShowDeleteModal(prev => !prev);
  }

  function togglePlayerInviteModal() {
    setShowInviteModal(prev => !prev);
  }

  async function requestToJoin() {
    if (requestSent || !props.headers || !props.headers?.userId) {
      return;
    }
    setIsLoadingRequest(true);
    try {
      const response = await callAxiosFunc({
        method: "POST",
        url: `${ENDPOINT.JOIN_TEAM}/${props?.id}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: props?.headers.encryptedHeader }),
      });
      const status = response?.data?.status;
      if (status === "pending-accept") {
        setRequestSent(true);
      }
    } catch (error) {
      console.log({ error });
      setIsLoadingRequest(false);
    }
    setIsLoadingRequest(false);
  }

  /**
   * The function `validateImageTransparency` checks if an image has transparent pixels by examining the
   * alpha channel of its pixel data.
   * @returns the boolean value `false`.
   */
  function validateImageTransparency(imageDataSrc) {
    const img = new Image();
    img.src = imageDataSrc;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height).data;

    // Check for transparency by looking at the alpha channel
    const hasTransparentPixels = imageData.some((alpha, index) => index % 4 === 3 && alpha < 255);

    if (hasTransparentPixels) {
      setIsThumbnailTransparent(true);
    } else {
      setIsThumbnailTransparent(false);
    }

    return false;
  }

  // TODO: Get languages from BE once data is available in the API
  const languages = ["en", "es", "fr", "pt", "tl"];

  const languageMap = {
    en: "English",
    es: "Spanish",
    fr: "French",
    pt: "Portuguese",
    tl: "Tagalog",
  };

  // The media argument specifies whether the incoming file is for the team thumbnail or banner
  async function handleFileUpload(e, media = "thumbnail") {
    const type = "team"; // type will be the same as all actions are being made for a team here
    const acceptableFormats = ["jpg", "jpeg", "png", "webp"];
    try {
      const file = e.target?.files[0];
      const fileName = file?.name;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length)?.toLowerCase();

      if (!acceptableFormats.includes(extFile)) {
        toast(
          <div>
            <Flex alignItems="center">
              <WarningIcon style={{ height: "40px", width: "90px" }} />
              <div>
                Invalid Format. Please upload files only with the following formats: {acceptableFormats.join(", ")}.
              </div>
            </Flex>
          </div>,
          toastOptions,
        );
        return;
      }

      if (media === "thumbnail") {
        setThumbnailUploading(true);
      } else if (media === "banner") {
        setBannerUploading(true);
      }
      const res = await uploadImge(e.target.files, type, props?.name, media);
      if (res && res.data && res.data.url) {
        const updateTeamResp = await props.updateTeamData({ [media]: res.data.url });
        setThumbnailUploading(false);
        setBannerUploading(false);
        if (updateTeamResp.err) {
          toast(
            <Flex alignItems="center">
              <WarningIcon style={{ height: "40px", width: "40px" }} /> Something went wrong. Please try again!
            </Flex>,
            toastOptions,
          );
          return;
        }
        if (media === "thumbnail") {
          const newThumbnail = updateTeamResp?.data?.thumbnail || "";
          setTeamThumbnail(`${newThumbnail}?random=${new Date().getSeconds()}`);
          if (extFile === "png") {
            validateImageTransparency(`${newThumbnail}?random=${new Date().getSeconds()}`);
          }
        } else if (media === "banner") {
          const newBanner = updateTeamResp?.data?.banner || "";
          setTeamBanner(`${newBanner}?random=${new Date().getSeconds()}`);
          setShowBannerList(false);
        }
        toast(
          <Flex alignItems="center">
            <Tick style={{ height: "15px", width: "15px", marginRight: "14px" }} />
            {media == "thumbnail" ? "Avatar" : "Banner"} Updated Successfully
          </Flex>,
          toastOptions,
        );
      } else if (res.err) {
        console.log("Error while uploading image: ", res.err);
      }
    } catch (err) {
      console.log("Error while uploading image: ", err);
    }
  }

  async function leaveTeam({ userId = "", teamId = "" }) {
    if (!userId) return;
    else {
      try {
        const response = await callAxiosFunc({
          method: "DELETE",
          url: `${ENDPOINT.LEAVE_TEAM}/${teamId}`,
          headers: JSON.stringify({ accept: "*/*", Authorization: props?.headers.encryptedHeader }),
          body: JSON.stringify({ userId: userId }),
        });

        if (response.err) {
          toast.error(
            <Flex alignItems="center">
              <WarningIcon style={{ height: "40px", width: "40px" }} />{" "}
              {response?.err?.response?.data?.message
                ? response?.err?.response?.data?.message?.[0]
                : "Something went wrong. Please try again!"}
            </Flex>,
            toastOptions,
          );
        } else {
          setDisableLeaveButton(true);
          toast.success(
            <Flex alignItems="center">
              <TickSuccess style={{ height: "40px", width: "40px" }} />{" "}
              {response?.data?.removeTeamMemberMessage
                ? response?.data?.removeTeamMemberMessage
                : "Something went wrong. Please try again!"}
            </Flex>,
            toastOptions,
          );
          setShowLeaveMenu(false);
          props.handleRemoveUser(props?.headers?.userId);
        }
      } catch (error) {
        console.error({ error });
      }
    }
  }

  function showInviteModalFunc() {
    if (
      props.sortedPlayers?.filter(player => player.status === "accepted" || player.status === "pending-confirm")
        ?.length >= props.usersLimit
    ) {
      toast(
        <div>
          <div>You cannot invite more players</div>
          <div>
            You have{" "}
            {
              props?.sortedPlayers?.filter(
                player => player.status === "accepted" || player.status === "pending-confirm",
              )?.length
            }{" "}
            active and {props.sortedPlayers?.filter(player => player.status === "pending-confirm")?.length} pending
            players
          </div>
        </div>,
        TOAST_OPTIONS,
      );
    } else {
      setShowInviteModal(true);
    }
  }

  const { isOwner, visibility } = props;

  useEffect(() => {
    validateImageTransparency(teamThumbnail);
  }, [teamThumbnail]);

  return (
    <>
      <ToastContainer />
      {/* Desktop Version */}
      <S.Container backgroundUrl={teamBanner ? `${teamBanner}` : defaultBanner}>
        {isOwner ? (
          <FlexStyled
            justifyContent="space-between"
            extraStyles={{ width: "100%", "padding-left": "38px", "padding-right": "38px", "padding-top": "16px" }}
          >
            <Flex
              columnGap="14"
              onClick={() => {
                if (props.scrollRequestsIntoView) {
                  props.scrollRequestsIntoView();
                }
              }}
            >
              {props?.playerRequests?.length > 0 && <NotificationIconRed />}
              {props?.playerRequests?.length > 0 && (
                <div style={{ cursor: "pointer" }}>
                  <TextNoMargin color="#A498ED" fontSize="16px">
                    {props?.playerRequests?.length} Pending requests
                  </TextNoMargin>
                </div>
              )}
            </Flex>
            <Flex>
              <BannerMenu
                toggleDeleteModal={toggleDeleteModal}
                showDeleteModal={showDeleteModal}
                setEditLanguage={setEditLanguage}
                setEditTeamName={setEditTeamName}
              />
            </Flex>
          </FlexStyled>
        ) : (
          <div style={{ paddingTop: "32px" }} />
        )}
        <S.BottomRow>
          <Flex columnGap="24" alignItems="flex-end">
            <S.AvatarContainer
              isOwner={isOwner}
              thumbnailUploading={thumbnailUploading}
              isThumbnailTransparent={isThumbnailTransparent}
            >
              <>
                {isOwner &&
                  (thumbnailUploading ? (
                    <span style={{ position: "absolute", left: "45%", top: "45%" }}>
                      <SpinnerLoader />
                    </span>
                  ) : (
                    <div onClick={handleProfilePicClick}>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={profilePicInputRef}
                        onChange={e => handleFileUpload(e, "thumbnail")}
                      />
                      <Camera />
                    </div>
                  ))}
              </>

              <span style={thumbnailUploading ? { opacity: "0.3" } : {}}>
                <Avatar src={`${teamThumbnail}`} team size="143px" />
              </span>
            </S.AvatarContainer>
            <Flex direction="column">
              <TeamNameInput
                editTeamName={editTeamName}
                setEditTeamName={setEditTeamName}
                teamName={teamName}
                setTeamName={setTeamName}
                updateTeamData={props.updateTeamData}
              />

              <Flex columnGap={21} extraStyles={{ "margin-top": "11px", height: "20px" }}>
                <Flex columnGap={10} alignItems="center">
                  <TeamIcon />
                  <TextNoMargin color="#A498ED" fontSize="16px" fontWeight="325" lineHeight="19px">
                    {props?.activeAndPendingMembers?.length} players
                  </TextNoMargin>
                </Flex>
                <Flex columnGap={10} alignItems="center">
                  <GlobeIcon />
                  <LanguageDropdown
                    editLanguage={editLanguage}
                    teamLanguage={teamLanguage}
                    setTeamLanguage={setTeamLanguage}
                    setEditLanguage={setEditLanguage}
                    updateTeamData={props.updateTeamData}
                    languages={languages}
                    languageMap={languageMap}
                  />
                </Flex>
                {isOwner && (
                  <Flex>
                    <VisibilityToggle
                      teamVisibility={teamVisibility}
                      setTeamVisibility={setTeamVisibility}
                      updateTeamData={props.updateTeamData}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>

          <Flex alignItems="center" columnGap="21">
            <Flex alignItems="center">
              <Flex extraStyles={{ "margin-right": "10px" }}>
                <TextNoMargin color="#A498ED" fontSize="16px">
                  {props?.activeAndPendingMembers?.length}/
                </TextNoMargin>
                <TextNoMargin color="#fff" fontSize="16px">
                  {props.usersLimit}
                </TextNoMargin>
              </Flex>
              <S.PlayerAvatarsContainer>
                <>
                  {props?.activeAndPendingMembers?.slice(0, 4).map((item, indx) => (
                    <Tooltip
                      title={item?.nick}
                      color="#7265C8"
                      overlayInnerStyle={{ fontSize: "12px", borderRadius: "4px" }}
                    >
                      <span>
                        <Avatar size="40px" key={indx} src={item?.thumbnail} />
                      </span>
                    </Tooltip>
                  ))}
                  {props?.activeAndPendingMembers?.length > 4 ? (
                    <S.PlayerCountCircle>+{props?.activeAndPendingMembers?.length - 4}</S.PlayerCountCircle>
                  ) : null}
                </>
              </S.PlayerAvatarsContainer>
            </Flex>

            {isOwner && (
              <>
                <Button
                  onClick={() => showInviteModalFunc()}
                  width="136px"
                  height="37px"
                  borderRadius="18.5px"
                  id="analytics-invite-players"
                  disabled={props.status === "inactive"}
                >
                  Invite Players
                </Button>
                <S.CameraContainer>
                  <Camera style={{ cursor: "pointer" }} onClick={() => setShowBannerList(true)} />
                </S.CameraContainer>
              </>
            )}

            {props.headers &&
              props.headers?.userId &&
              !isOwner &&
              props.players
                .filter(player => !["deleted", "pending-confirm", "pending-accept"].includes(player?.status))
                ?.map(player => player.userId)
                .includes(props.headers?.userId) && (
                <div style={{ position: "relative" }}>
                  <Button
                    onClick={() => setShowLeaveMenu(prev => !prev)}
                    width="136px"
                    height="37px"
                    borderRadius="18.5px"
                    disabled={disableLeaveButton || props.status === "inactive"}
                  >
                    Leave Team
                  </Button>
                  {showLeaveMenu && (
                    <S.Popover style={{ width: "200px", height: "min-content", top: "50px", padding: "20px" }}>
                      <text>Do you confirm you want to leave the team?</text>
                      <Button
                        height="37px"
                        borderRadius="18.5px"
                        onClick={() => {
                          leaveTeam({ userId: props?.headers?.userId, teamId: teamId });
                        }}
                      >
                        Confirm
                      </Button>
                    </S.Popover>
                  )}
                </div>
              )}

            {props.headers &&
              props.headers?.userId &&
              visibility !== "hidden" &&
              !isOwner &&
              !props.players
                .filter(player => player.status === "accepted")
                ?.map(player => player.userId)
                .includes(props.headers?.userId) && (
                <>
                  <Button
                    onClick={() => requestToJoin()}
                    width="136px"
                    height="37px"
                    borderRadius="18.5px"
                    hoverStyles={{ height: "34px" }}
                    disabled={props.status === "inactive"}
                  >
                    {isLoadingRequest ? <SpinnerLoader /> : requestSent ? "Request Sent" : "Request To Join"}
                  </Button>
                </>
              )}
          </Flex>
        </S.BottomRow>
      </S.Container>

      {/* Mobile Version */}
      <S.MobileBanner backgroundUrl={teamBanner ? `${teamBanner}` : defaultBanner}>
        <div
          style={{
            position: "absolute",
            right: "12px",
            display: "flex",
            top: "10px",
            columnGap: "17px",
            alignItems: "flex-end",
          }}
        >
          {isOwner && (
            <div style={{ display: "flex", gap: "12px" }}>
              <NotificationIconRed
                onClick={() => {
                  if (props.scrollRequestsIntoView) {
                    props.scrollRequestsIntoView();
                  }
                }}
              />
              <Camera onClick={toggleShowBannerList} />
            </div>
          )}
          <BannerMenu
            toggleDeleteModal={toggleDeleteModal}
            showDeleteModal={showDeleteModal}
            setEditLanguage={setEditLanguage}
            setEditTeamName={setEditTeamName}
          />
        </div>
        <div style={{ position: "absolute", left: "12px", top: "10px" }}>
          {/* <FlexStyled>
            <TextNoMargin color="#A498ED" fontSize="16px">
              {props?.activeAndPendingMembers?.length}/
            </TextNoMargin>
            <Text fontSize="16px"> {props.usersLimit}</Text>
          </FlexStyled> */}
          {isOwner && (
            <Flex>
              <VisibilityToggle
                teamVisibility={teamVisibility}
                setTeamVisibility={setTeamVisibility}
                updateTeamData={props.updateTeamData}
              />
            </Flex>
          )}
        </div>
        <S.BottomRow isMobile>
          <S.AvatarContainer isMobile isOwner={isOwner} isThumbnailTransparent={isThumbnailTransparent}>
            {isOwner &&
              (thumbnailUploading ? (
                <span style={{ position: "absolute", left: "45%", top: "45%" }}>
                  <SpinnerLoader />
                </span>
              ) : (
                <div onClick={handleProfilePicClick}>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={profilePicInputRef}
                    onChange={e => handleFileUpload(e, "thumbnail")}
                  />
                  <Camera />
                </div>
              ))}
            <span style={thumbnailUploading ? { opacity: "0.3" } : {}}>
              <Avatar src={`${teamThumbnail}`} team size="89px" />
            </span>
          </S.AvatarContainer>
        </S.BottomRow>
      </S.MobileBanner>

      <S.MobileContainer>
        <FlexStyled justifyContent="space-between" extraStyles={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            {/* <TextNoMargin fontSize="28px">{props.teamName}</TextNoMargin> */}
            <div style={{ display: "flex", columnGap: "12px", justifyContent: "center" }}>
              <TeamNameInput
                editTeamName={editTeamName}
                setEditTeamName={setEditTeamName}
                teamName={teamName}
                setTeamName={setTeamName}
                updateTeamData={props.updateTeamData}
              />

              <Flex alignItems="center">
                <TextNoMargin style={{ color: "#A498ED", fontSize: "16px" }}>
                  {props?.activeAndPendingMembers?.length}/
                </TextNoMargin>
                <TextNoMargin style={{ fontSize: "16px" }}> {props.usersLimit}</TextNoMargin>
              </Flex>
            </div>
            <Flex columnGap={21} justifyContent="center" extraStyles={{ "margin-top": "11px" }}>
              <Flex columnGap={10} alignItems="center">
                <TeamIcon />
                <TextNoMargin color="#A498ED">{props?.activeAndPendingMembers?.length} players</TextNoMargin>
              </Flex>
              <Flex columnGap={10} alignItems="center">
                <GlobeIcon />
                <LanguageDropdown
                  editLanguage={editLanguage}
                  teamLanguage={teamLanguage}
                  setTeamLanguage={setTeamLanguage}
                  setEditLanguage={setEditLanguage}
                  updateTeamData={props.updateTeamData}
                  languages={languages}
                  languageMap={languageMap}
                />
              </Flex>
            </Flex>
          </div>
        </FlexStyled>

        <div style={{ marginTop: "16px", display: "flex", width: "100%", justifyContent: "center", columnGap: "21px" }}>
          <S.PlayerAvatarsContainer>
            <>
              {props?.activeAndPendingMembers?.slice(0, 3).map(item => (
                <Avatar size="40px" src={item?.thumbnail} />
              ))}
              {props?.activeAndPendingMembers?.length > 3 ? (
                <S.PlayerCountCircle>+{props?.activeAndPendingMembers?.length - 3}</S.PlayerCountCircle>
              ) : null}
            </>
          </S.PlayerAvatarsContainer>
          {isOwner && (
            <Button
              onClick={() => showInviteModalFunc()}
              width="136px"
              height="37px"
              borderRadius="18.5px"
              id="analytics-invite-players"
              disabled={props.status === "inactive"}
            >
              Invite Players
            </Button>
          )}
          {props.headers &&
            props.headers?.userId &&
            !isOwner &&
            props.players
              .filter(player => !["deleted", "pending-confirm", "pending-accept"].includes(player?.status))
              ?.map(player => player.userId)
              .includes(props.headers?.userId) && (
              <div style={{ position: "relative" }}>
                <Button
                  onClick={() => setShowLeaveMenu(prev => !prev)}
                  width="136px"
                  height="37px"
                  borderRadius="18.5px"
                  disabled={disableLeaveButton || props.status === "inactive"}
                >
                  Leave Team
                </Button>
                {showLeaveMenu && (
                  <S.Popover style={{ width: "200px", height: "min-content", top: "50px", padding: "20px" }}>
                    <text>Do you confirm you want to leave the team?</text>
                    <Button
                      height="37px"
                      borderRadius="18.5px"
                      onClick={() => {
                        leaveTeam({ userId: props?.headers?.userId, teamId: teamId });
                      }}
                    >
                      Confirm
                    </Button>
                  </S.Popover>
                )}
              </div>
            )}

          {props.headers &&
            props.headers?.userId &&
            visibility !== "hidden" &&
            !isOwner &&
            !props.players
              .filter(player => player.status === "accepted")
              ?.map(player => player.userId)
              .includes(props.headers?.userId) && (
              <>
                <Button
                  onClick={() => requestToJoin()}
                  width="136px"
                  height="37px"
                  borderRadius="18.5px"
                  hoverStyles={{ height: "34px" }}
                  disabled={props.status === "inactive"}
                >
                  {isLoadingRequest ? <SpinnerLoader /> : requestSent ? "Request Sent" : "Request To Join"}
                </Button>
              </>
            )}
        </div>
      </S.MobileContainer>

      <Modal showModal={showBannerList} toggleModal={toggleShowBannerList}>
        <TextNoMargin fontSize="26px">Select a different banner or upload your own</TextNoMargin>
        {bannerUploading ? (
          <Flex alignItems="center" justifyContent="center" extraStyles={{ height: "426px", width: "400px" }}>
            <SpinnerLoader />
          </Flex>
        ) : (
          <S.BannerListContainer>
            <>
              <S.BannerPicker onClick={handleBannerSelectionClick}>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={bannerPicInputRef}
                  onChange={e => handleFileUpload(e, "banner")}
                />
                <Camera /> {/* //TODO: Update text */}
              </S.BannerPicker>
              {props.bannerImages.map(item => (
                <S.BannerListItem
                  backgroundUrl={item}
                  onClick={() => setSelectedBannerImage(item)}
                  isSelected={selectedBannerImage === item}
                ></S.BannerListItem>
              ))}
            </>

            {/* Empty Div To Maintain UI */}
            <S.Empty />

            <S.UploadBtn
              disabled={!selectedBannerImage}
              onClick={async () => {
                setBannerUploading(true);
                const item = selectedBannerImage;
                setSelectedBannerImage("");
                let res = await props.updateTeamData({ banner: item });

                setBannerUploading(false);
                if (res.err || res.error) {
                  console.log("Error while updating team banner image: ", res.err || res.error);
                } else {
                  setTeamBanner(res?.data?.banner);
                  setShowBannerList(false);
                  toast(
                    <Flex alignItems="center">
                      <Tick style={{ height: "15px", width: "15px", marginRight: "14px" }} />
                      Banner Updated Successfully
                    </Flex>,
                    toastOptions,
                  );
                }
              }}
            >
              Confirm
            </S.UploadBtn>
          </S.BannerListContainer>
        )}
      </Modal>

      <PlayerInviteModal
        handlePlayersListState={props.handlePlayersListState}
        showModal={showInviteModal}
        toggleModal={togglePlayerInviteModal}
        teamId={props.id}
        playersList={props.sortedPlayers}
        usersLimit={props.usersLimit}
      />

      <DeleteModal showDeleteModal={showDeleteModal} toggleDeleteModal={toggleDeleteModal} teamId={props.id} />
    </>
  );
};

export default TeamProfileBanner;
