/* eslint-disable no-useless-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
import React, { useState } from "react";
import { Select } from "antd";
import * as crypto from "crypto-js";
import { toast } from "react-toastify";
import * as S from "./styles";
import { DoubleArrow, DownArrow, EditIcon, NewWindowIcon, RedCross, TickWithBorder } from "../../assets/svg";
import { useGetSecHeaders } from "../../hooks/SecurityHeaders";
import { useRecoilState } from "recoil";
import { sessionState } from "../../state/globalState";
import { useEffect } from "react";
import { BE_API_BASE_URL, SEED } from "../../constants/env";
import useCallAxios from "../../hooks/useAxiosCall";
import { useRef } from "react";
import { setUserInLocalStorage } from "../../helpers/localStorage";
import Flex from "../../components/v2/common/Flex";
import { TOAST_OPTIONS } from "../../constants";
import { IconButton } from "../../components/v2/common/Button";

const { Option } = Select;

const gamingAccounts = {
  steam: "Steam",
  xbox: "Xbox",
  psn: "Playstation",
};

const options = {
  autoClose: 6000,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  style: {
    color: "#D1CCEF",
    background: "#3A2F81",
    width: "204px",
    borderRadius: "6px",
    float: "right",
    marginRight: "63px",
  },
  closeButton: false,
};

export default function AccountCard({ gameData, afterConnectCb }) {
  const {
    name,
    platforms,
    subName,
    tutorial,
    accConnPlaceholder,
    visibleName,
    assets: { profileIcon, profileLogo },
  } = gameData;

  const [visibility, setVisibility] = useState(false);

  const handleToggleVisibility = () => {
    setVisibility(!visibility);
  };

  const headers = useGetSecHeaders();
  const [session, setSession] = useRecoilState(sessionState);

  const inputRef = useRef(null);

  const [selectedPlatform, setSelectedPlatform] = useState(platforms.length > 1 ? null : platforms[0]);
  const [userTag, setUserTag] = useState("");
  const [errorState, setErrorState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // This state variable will be used to determine if the address for a certain platform/game can be edited
  const [isEditable, setIsEditable] = useState(true);

  // This state variable will toggle enabled state of inputs of platforms in which Blast allows users to change their address
  const [addressEditEnabled, setAddressEditEnabled] = useState(false);

  const { callAxiosFunc } = useCallAxios();

  const submitHandler = async () => {
    let accountName = userTag || inputRef.current.value;
    const nicknameToCompare =
      session?.user?.accounts?.find(acc => platforms.includes(acc.subPlatform))?.username ||
      session?.user?.accounts?.find(acc => platforms.includes(acc.platform))?.username;
    setErrorState("");
    if (!accountName) {
      setErrorState("Please provide a user ID");
      return;
    }
    if (!selectedPlatform) {
      setErrorState("Please select a platform");
      return;
    }

    if (accountName === nicknameToCompare) {
      setErrorState("You are trying to reuse your current username");
      return;
    }

    setIsLoading(true);
    const response = await callAxiosFunc({
      method: "PUT",
      url: `${BE_API_BASE_URL}user/account/${
        name === "PUBG" ? "pubg" : gameData?.platforms?.[0] //TODO: remove this on next sprint
      }/${selectedPlatform}/${encodeURIComponent(accountName.trim())}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });

    if (response?.data?.error) {
      setErrorState(response?.data?.msg || "Registration failed. Please try again.");
      toast(
        <div style={{ display: "flex", justifyContent: "center", gap: "13px", alignItems: "center" }}>
          <RedCross style={{ height: "12px", width: "12px" }} />
          {response?.data?.msg || "Registration failed. Please try again."}
        </div>,
        TOAST_OPTIONS,
      );
    }
    if (response?.data && response?.data?.account) {
      const filteredAccounts = session.user.accounts.filter(item => item.name != response?.data?.account?.name);
      const updatedAccounts = [...filteredAccounts, response?.data?.account];
      const updatedSession = {
        ...session,
        user: {
          ...session.user,
          accounts: updatedAccounts,
        },
      };
      setSession(updatedSession);
      const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
      setUserInLocalStorage(encryptedState);
      setAddressEditEnabled(false);
      toast.success(
        <div style={{ display: "flex", justifyContent: "center", gap: "13px" }}>
          <TickWithBorder />
          {/* Logic for the check below is mentioned in a useEffect further down */}
          Account Connected Succesfully
        </div>,
        TOAST_OPTIONS,
      );

      if (afterConnectCb) afterConnectCb();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (name === "PUBG") {
      if (session?.user?.accounts?.find(acc => platforms.includes(acc.subPlatform))) {
        setIsChecked(true);
      }
      setSelectedPlatform(session?.user?.accounts?.find(acc => acc.platform === "pubg")?.subPlatform);
    } else if (name === "VALORANT") {
      if (session?.user?.accounts?.find(acc => platforms.includes(acc.subPlatform))) {
        setIsChecked(true);
        setSelectedPlatform(session?.user?.accounts?.find(acc => acc.platform === "valorant")?.subPlatform);
      } else {
        setSelectedPlatform("valorant");
      }
    } else if (name === "LEAGUE OF LEGENDS") {
      if (session?.user?.accounts?.find(acc => platforms.includes(acc.subPlatform))) {
        setIsChecked(true);
        setSelectedPlatform(session?.user?.accounts?.find(acc => acc.platform === "league_of_legends")?.subPlatform);
      } else {
        setSelectedPlatform("league_of_legends");
      }
    } else {
      if (session?.user?.accounts?.find(acc => platforms.includes(acc.platform))) {
        setIsChecked(true);
      }
    }
  }, [platforms, session, name]);

  return (
    <S.GameAccordionWrapper visible={visibility} isLoading={isLoading}>
      <S.AccordionHeader onClick={handleToggleVisibility} visible={visibility}>
        <IconButton>
          <DoubleArrow id="toggle-content-arrow" />
        </IconButton>
        <img src={profileIcon} style={{ height: "72px", width: "72px" }} alt="Profile Icon" />
        <Flex direction="column" justifyContent="center" alignItems="flex-start" rowGap="10">
          <S.AccountStatusContainer connected={isChecked}>
            {isChecked ? (
              <S.StatusText connected={isChecked}>
                <Flex justifyContent="center" alignItems="center" columnGap="7">
                  <span
                    style={{
                      border: "2px solid #B2FA4F",
                      background: "#B2FA4F",
                      height: "8px",
                      width: "8px",
                      borderRadius: "100px",
                    }}
                  />
                  <div>Connected</div>
                </Flex>
              </S.StatusText>
            ) : (
              <S.StatusText>Not connected</S.StatusText>
            )}
            {/* //TODO:  Replace texts with the verified ones when the endpoint is ready */}
          </S.AccountStatusContainer>
          <Flex direction="column">
            <S.GameName>{visibleName}</S.GameName>
            {visibility && (
              <div>
                <S.SetupTextWrapper>
                  <S.SetupText href={tutorial} target="_blank">
                    How to setup account
                    <NewWindowIcon />
                  </S.SetupText>
                </S.SetupTextWrapper>
              </div>
            )}
          </Flex>
        </Flex>
      </S.AccordionHeader>
      <S.AccordionContent visible={visibility}>
        <div>
          <S.ControlsContainer>
            {platforms.length > 1 && (
              <S.SelectContainer>
                <Select
                  onChange={value => {
                    setSelectedPlatform(value);
                  }}
                  dropdownStyle={{ background: "#4E3CCA", width: "100%" }}
                  placeholder="Platform"
                  disabled={isChecked || isLoading}
                  value={selectedPlatform}
                  suffixIcon={<DownArrow />}
                >
                  {platforms.map(item => (
                    <Option
                      style={{
                        color: "#fff",
                        background:
                          selectedPlatform === item
                            ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
                            : "#4E3CCA",
                      }}
                      value={item}
                    >
                      {gamingAccounts[item] ? gamingAccounts[item] : item}
                    </Option>
                  ))}
                </Select>
              </S.SelectContainer>
            )}
            <div>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                <S.FloatLabel>
                  <p>{accConnPlaceholder || `${name} ID`} </p>
                  <S.CardInput
                    type="text"
                    isEditable={isEditable}
                    ref={inputRef}
                    disabled={isLoading || (isEditable && isChecked) ? !addressEditEnabled : isChecked}
                    onChange={e => setUserTag(e.target.value)}
                    placeholder="user1234"
                    defaultValue={
                      name === "PUBG"
                        ? session?.user?.accounts?.find(acc => platforms.includes(acc.subPlatform))?.username
                        : session?.user?.accounts?.find(acc => platforms.includes(acc.platform))?.username
                    }
                  />
                </S.FloatLabel>
                {/* If editable, show edit icon. Else, if the platform does not allow edits, show toggle, else show nothing (nothing signfies that editing is allowed but currently these is no value to edit) */}
                {isEditable ? (
                  <EditIcon
                    onClick={() => setAddressEditEnabled(!addressEditEnabled)}
                    style={{ cursor: "pointer", transform: "scale(1.2)", minWidth: "20px" }}
                  />
                ) : (
                  <div style={{ minWidth: "44px" }} />
                )}
              </div>
              {errorState && (
                <S.ErrorText>
                  {errorState === "Invalid User" ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>There was an issue trying to save your account.</div>
                      <div>If this error persists you can talk to us through discord</div>
                    </div>
                  ) : (
                    <div>{errorState}</div>
                  )}
                </S.ErrorText>
              )}
            </div>
          </S.ControlsContainer>
        </div>
        <S.ButtonWrap>
          <S.VerifyBtn
            disabled={isLoading || (isEditable && isChecked) ? !addressEditEnabled : isChecked}
            onClick={submitHandler}
            id="analytics-connect-gaming-account"
          >
            {isLoading ? <S.Loader /> : "Connect"}
          </S.VerifyBtn>
        </S.ButtonWrap>
      </S.AccordionContent>
    </S.GameAccordionWrapper>
  );
}
